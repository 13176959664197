export const GeoPermissions = Object.freeze({
    GRANTED: "granted",
    PROMPT: "prompt",
    DENIED: "denied"
})

export const GeolocationPermissionDecisions = Object.freeze({
    GRANTED: "Accepted",
    PROMPT: "Not_Decided",
    DENIED: "Declined",
    FAILED: "Failed"
})

export const GeolocationErrors = Object.freeze([
    'User declined geolocation',
    'Geolocation position is unavailable due to internal error',
    'Geolocation timed-out'
])