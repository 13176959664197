import { getSpecialtyName } from '../data-mappers/specialty-ids.js'

export const getURLParameters = () => {
  let urlParams = {
    search: {},
    filters: {},
    sort: ''
  }

  let q = getParameterByName('q')
  let locq = getParameterByName('locq')
  let locId = getParameterByName('locId')
  let ids = getParameterByName('ids')
  let lat = getParameterByName('lat')
  let lng = getParameterByName('lng')
  let dist = getParameterByName('dist')
  let sort = getParameterByName('sort')
  let searchRadius = getParameterByName('searchRadius')
  let app = getParameterByName('app')
  let selectedAvailability = getParameterByName('accpat')
  let selectedRating = getParameterByName('rating')
  let selectedSpecialty = getParameterByName('spec')
  let selectedDoctorType = getParameterByName('doctype')
  let selectedGender = getParameterByName('gender')
  let selectedLanguage = getParameterByName('lang')
  let selectedHospital = getParameterByName('hosp')
  let selectedAffiliation = getParameterByName('affiliation')
  let selectedAppointmentDate = getParameterByName('apptDate')
  let selectedTimeSlots = getParameterByName('times')
  let selectedWeekendHours = getParameterByName('weekendHrs')
  let pageNumber = getParameterByName('page')
  let showAll = getParameterByName('showAll')
  let selectedClassType = getParameterByName('classType')
  let selectedCategories = getParameterByName('categories')
  let selectedLocations = getParameterByName('locations')
  let providers = getParameterByName('providers')
  let groups = getParameterByName('groups')

  if (q) urlParams.search.q = q.replace(/\+/g, ' ')
  if (locq) urlParams.search.locq = locq.replace(/\+/g, ' ')
  if (lat) urlParams.search.lat = lat.replace(/\+/g, ' ')
  if (lng) urlParams.search.lng = lng.replace(/\+/g, ' ')
  if (dist) urlParams.search.dist = dist.replace(/\+/g, ' ')
  if (app) urlParams.search.app = app.replace(/\+/g, ' ')
  if (locId) urlParams.search.locId = locId.replace(/\+/g, ' ')
  if (ids) urlParams.search.locId = ids.replace(/\+/g, ' ').split('|')
  if (searchRadius) urlParams.search.searchRadius = searchRadius.replace(/\+/g, ' ')
  if (pageNumber) urlParams.search.page = pageNumber
  if (showAll) urlParams.showAll = showAll === 'true'
  if (groups) urlParams.filters.selectedLocationGroups = groups.replace(/\+/g, ' ').split('|')
  if (providers) urlParams.filters.selectedProviders = providers.replace(/\+/g, ' ').split('|')

  if (selectedDoctorType) urlParams.filters.selectedDoctorType = selectedDoctorType ? selectedDoctorType.replace(/\+/g, ' ').split('|') : []
  if (selectedGender) urlParams.filters.selectedGender = selectedGender ? selectedGender.replace(/\+/g, ' ').split('|') : []
  if (selectedLanguage) urlParams.filters.selectedLanguage = selectedLanguage ? selectedLanguage.replace(/\+/g, ' ').split('|') : []
  if (selectedHospital) urlParams.filters.selectedHospital = selectedHospital ? selectedHospital.replace(/\+/g, ' ').split('|') : []
  if (selectedAffiliation) urlParams.filters.selectedAffiliation = selectedAffiliation ? selectedAffiliation.replace(/\+/g, ' ').split('|') : []
  if (selectedAppointmentDate) urlParams.filters.selectedAppointmentDate = selectedAppointmentDate || ''
  if (selectedTimeSlots) urlParams.filters.selectedTimeSlots = selectedTimeSlots ? selectedTimeSlots.replace(/\+/g, ' ').split('|') : []
  if (selectedWeekendHours) urlParams.filters.selectedWeekendHours = selectedWeekendHours ? selectedWeekendHours.replace(/\+/g, ' ').split('|') : []
  if (selectedClassType) urlParams.filters.selectedClassType = selectedClassType ? selectedClassType.replace(/\+/g, ' ').split('|') : []
  if (selectedCategories) urlParams.filters.selectedCategories = selectedCategories ? selectedCategories.replace(/\+/g, ' ').split('|') : []
  if (selectedLocations) urlParams.filters.selectedLocations = selectedLocations ? selectedLocations.replace(/\+/g, ' ').split('|') : []
  if (selectedRating) urlParams.filters.selectedRating = selectedRating ? selectedRating.replace(/\+/g, ' ').split('|') : []

  if (selectedAvailability) {
    let availabilityArray = selectedAvailability.replace(/\+/g, ' ').split('|')
    urlParams.filters.selectedAvailability = availabilityArray.map((type) => {
      if (type === 'anp') {
        return 'Doctors Accepting New Patients'
      } else if (type === 'oos') {
        return 'Doctors with Online Scheduling'
      } else {
        return type
      }
    })
  }

  if (selectedSpecialty) {
    let specialtiesArray = selectedSpecialty.replace(/\+/g, ' ').split('|')
    urlParams.filters.selectedSpecialty = specialtiesArray.map((specialtyID) => {
      return getSpecialtyName(specialtyID)
    })
  }

  if (sort) {
    urlParams.sort = convertSort(sort)
  } else {
    urlParams.sort = 'A TO Z'
  }

  return urlParams
}

export const convertSort = (sort) => {
  switch (sort) {
    case 'az':
      return 'A TO Z'
    case 'za':
      return 'Z TO A'
    case 'distance':
      return 'Distance'
    case 'most-reviewed':
      return 'Most Reviewed'
    case 'top-rated':
      return 'Top Rated'
    case 'rand':
      return 'Random'
    case 'first-available':
      return 'First Available'
    default:
      return 'A TO Z'
  }
}

export const insertFiltersIntoURLParameters = (paramObject, type, filterValues) => {
  switch (type) {
    case 'SET_SELECTED_AVAILABILITY':
      paramObject.filters.selectedAvailability = filterValues
      break
    case 'SET_SELECTED_RATING':
      paramObject.filters.selectedRating = filterValues
      break
    case 'SET_SELECTED_SPECIALTY':
      paramObject.filters.selectedSpecialty = filterValues
      break
    case 'SET_SELECTED_DOCTOR_TYPE':
      paramObject.filters.selectedDoctorType = filterValues
      break
    case 'SET_SELECTED_GENDER':
      paramObject.filters.selectedGender = filterValues
      break
    case 'SET_SELECTED_LANGUAGE':
      paramObject.filters.selectedLanguage = filterValues
      break
    case 'SET_SELECTED_HOSPITAL':
      paramObject.filters.selectedHospital = filterValues
      break
    case 'SET_SELECTED_AFFILIATION':
      paramObject.filters.selectedAffiliation = filterValues
      break
    case 'SET_SELECTED_WEEKEND_HOURS':
      paramObject.filters.selectedWeekendHours = filterValues
      break
    case 'SET_SELECTED_DATE':
      paramObject.filters.selectedAppointmentDate = filterValues
      break
    case 'SET_SELECTED_TIME_SLOTS':
      paramObject.filters.selectedTimeSlots = filterValues
      break
    case 'SET_SELECTED_PROVIDERS':
      paramObject.filters.selectedProviders = filterValues
      break
    case 'SET_SELECTED_GROUPS':
      paramObject.filters.selectedLocationGroups = filterValues
      break
    case 'SET_SELECTED_CLASS_TYPE':
      paramObject.filters.selectedClassType = filterValues
      break
    case 'SET_SELECTED_CLASS_CATEGORY':
      paramObject.filters.selectedCategories = filterValues
      break
    case 'SET_SELECTED_CLASS_LOCATION':
      paramObject.filters.selectedLocations = filterValues
      break
  }

  return paramObject
}

export const insertEmptyFilters = (paramObject) => {
  paramObject.filters = {
    selectedAvailability: [],
    selectedRating: [],
    selectedSpecialty: [],
    selectedDoctorType: [],
    selectedGender: [],
    selectedLanguage: [],
    selectedHospital: [],
    selectedAffiliation: [],
    selectedClassType: [],
    selectedCategories: [],
    selectedLocations: [],
    selectedTags: []
  }

  paramObject.sort = convertSort('az')

  return paramObject
}

export const getLookingFor = () => {
  let param = getParameterByName('q')
  return param && param.replace('+', ' ')
}

export const getPageNumber = () => {
  return getParameterByName('page')
}

export const getID = () => {
  return getParameterByName('id')
}

export const getShowAll = () => {
  return window.location.href.indexOf('showAll') > -1
}

export const shouldScrollToRatingsAndReviews = () => {
  return getParameterByName('rar') === 'true'
}

export const getParameterByName = (name) => {
  let url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  let results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2])
}
