import { connect } from 'react-redux'
import FindPage from '../FindPage.jsx'
import { getFilteredProviderCount, getAffiliatedProviders, getAffiliatedProviderCount, getPracticingProviders, getDoctorSearchFilterCount, allDoctors, getSearchQuery } from '../selectors/doctor-search-filter-selectors'
import { getURLParameters, insertEmptyFilters } from '../../shared/utility-functions/searchParams.js'
import { insertBrowserHistory } from '../state/history'
import isEmptyObject from '../../shared/utility-functions/isEmptyObject'

const mapStateToProps = (state, ownProps) => {
  return ({
    searchOptions: ownProps.searchOptions || {},
    filterOptions: ownProps.filterOptions,
    searchType: 'doctor',
    query: getSearchQuery(state),
    allDoctors: allDoctors(state),
    headline: ownProps.headline,
    providerCardOptions: ownProps.providerCardOptions,
    fadSearchedBounds: (state.fadMap && state.fadMap.searchedBounds),
    autoSearchedLocation: (state.fadMap && state.fadMap.autoSearchedLocation) ? state.fadMap.autoSearchedLocation : null,
    urlParameters: getURLParameters(),
    searchItems: state.doctorSearch.showAffiliatedDoctors ? getAffiliatedProviders(state) : getPracticingProviders(state),
    searchItemCount: state.doctorSearch.showAffiliatedDoctors ? getAffiliatedProviderCount(state) : getFilteredProviderCount(state),
    filteredProviderCount: getFilteredProviderCount(state),
    affiliatedProviderCount: getAffiliatedProviderCount(state),
    showAffiliatedProviderMessage: state.doctorSearch.showAffiliatedDoctors,
    dataLoaded: state.doctorSearch.dataLoaded,
    searched: state.doctorSearch.searched,
    fetchCallFailed: state.doctorSearch.callFailed,
    flow: ownProps.flow,
    sort: state.doctorSearch.sortOrder,
    filters: state.doctorSearch.filters,
    filterCount: getDoctorSearchFilterCount(state),
    orgID: ownProps.searchOptions.orgID,
    addressKeys: ownProps.searchOptions && ownProps.searchOptions.addressKeys ? ownProps.searchOptions.addressKeys.replace(/\s/g, '') : '',
    itemIds: ownProps.searchOptions && ownProps.searchOptions.connectHealthCareIDs ? ownProps.searchOptions.connectHealthCareIDs.replace(/\s/g, '') : '',
    app: ownProps.searchOptions.app ? ownProps.searchOptions.app : false
  })
}

const mapDispatchToProps = (dispatch) => ({
  setPage: (flow) => dispatch([
    {
      type: 'SET_SCHEDULING_TYPE',
      value: flow
    }
  ]),
  setOPG: (value) => dispatch({
    type: 'SET_OPG',
    value: value
  }),
  setSort: (sortOrder) => dispatch({
    type: 'SET_DOCTOR_SEARCH_SORT_ORDER',
    value: sortOrder
  }),
  showAlertModal: (value, clearAll) => dispatch({
    type: 'DISPLAY_FIRST_PCP_MODAL',
    value,
    clearAll
  }),
  clearPage: () => dispatch([
    {
      type: 'CLEAR_STORE'
    }
  ]),
  fetchAutocomplete: (isCIN, orgID, addressKeys, itemIds, app) => {
    return new Promise((resolve) => {
      dispatch([
        {
          type: 'GET_AUTOCOMPLETE_DATA',
          org: orgID,
          addKey: addressKeys,
          ids: itemIds,
          app: app,
          cin: isCIN,
          resolve: resolve
        }
      ])
    })
  },
  runSearch: (params, isBlock) => dispatch(
    {
      type: 'GET_FAD_PROVIDERS',
      params: params,
      isBlock: isBlock
    }
  ),
  setFilters: (params) => dispatch({
    type: 'SET_FILTERS',
    params: params
  }),
  resetFilters: () => dispatch({
    type: 'RESET_SCHEDULE_A_DOCTOR_FILTERS'
  }),
  resetSort: (value) => dispatch({ 
      type: 'SET_DOCTOR_SEARCH_SORT_ORDER',
      value: value
  }),
  toggleAffiliatedDoctors: () => dispatch({
    type: 'TOGGLE_AFFILIATED_DOCTORS'
  }),
  setPageNumber: (number) => dispatch({
    type: 'SET_DOCTOR_CURRENT_PAGE',
    number: number
  }),
  setAPPOverride: () => dispatch({
    type: 'APP_OVERRIDE'
  }),
  hideSchedulingModal: () => {
    dispatch({ type: 'HIDE_SCHEDULING_MODAL' })
  }
})

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  initializePage: async () => {
    if (stateProps.searchOptions.defaultSort) {
      dispatchProps.setSort(stateProps.searchOptions.defaultSort)
    }
    dispatchProps.setPage(stateProps.flow)
    if (stateProps.searchOptions.overrideAPP) {
      dispatchProps.setAPPOverride()
    }
    if (stateProps.flow !== 'cin') {
      await dispatchProps.fetchAutocomplete(false, stateProps.orgID, stateProps.addressKeys, stateProps.itemIds, stateProps.app)
      dispatchProps.setOPG(stateProps.app && stateProps.searchOptions.isBlock)
      if (stateProps.orgID.length) {
        dispatchProps.runSearch(searchCheck({ org: stateProps.orgID, app: stateProps.app }, stateProps.searchOptions), false)
      } else if (stateProps.addressKeys.length) {
        dispatchProps.runSearch(searchCheck({ addKey: stateProps.addressKeys, app: stateProps.app }, stateProps.searchOptions), false)
      } else if (stateProps.itemIds.length) {
        dispatchProps.runSearch(searchCheck({ ids: stateProps.itemIds, app: stateProps.app }, stateProps.searchOptions), false)
      } else if (stateProps.flow === 'fad' || (stateProps.flow === 'sad' && !(stateProps.providers && stateProps.providers.length))) {
        let searchObj = stateProps.urlParameters.search
        if (stateProps.app) {
          searchObj.app = true
        }
        dispatchProps.runSearch(searchObj, false)
      }
    } else {
      await dispatchProps.fetchAutocomplete(true)
      dispatchProps.runSearch(searchCheck({}, stateProps.searchOptions))
    }

    // Default ANP logic
    if (stateProps.filterOptions && stateProps.filterOptions.defaultANP && stateProps.filterOptions.showAcceptingNewScheduling) {
      let urlParams = stateProps.urlParameters

      if (urlParams.filters.selectedAvailability && !urlParams.filters.selectedAvailability.find(f => f === 'Doctors Accepting New Patients')) {
        urlParams.filters.selectedAvailability.push('Doctors Accepting New Patients')
      } else {
        urlParams.filters = {
          selectedAvailability: ['Doctors Accepting New Patients']
        }
      }

      if (stateProps.searchOptions && stateProps.searchOptions.enableUrl) {
        insertBrowserHistory({...urlParams, sort: stateProps.sort})
      }
    }

    // Default OOS logic
    if (stateProps.filterOptions && stateProps.filterOptions.defaultOOS) {
      let urlParams = stateProps.urlParameters

      if (urlParams.filters.selectedAvailability && !urlParams.filters.selectedAvailability.find(f => f === 'Doctors with Online Scheduling')) {
        urlParams.filters.selectedAvailability.push('Doctors with Online Scheduling')
      } else {
        urlParams.filters = {
          selectedAvailability: ['Doctors with Online Scheduling']
        }
      }

      if (stateProps.searchOptions && stateProps.searchOptions.enableUrl) {
        insertBrowserHistory({...urlParams, sort: stateProps.sort})
      }
    }

    if (!isEmptyObject(stateProps.urlParameters.filters)) {
      dispatchProps.setFilters(stateProps.urlParameters.filters)
    }
  },
  runSearch: (params, isBlock) => {
    let oldQuery = stateProps.query
    if (oldQuery !== params.q) {
      dispatchProps.resetFilters()
      dispatchProps.setSort('A TO Z')
      if (stateProps.searchOptions && stateProps.searchOptions.enableUrl) {
        insertBrowserHistory(insertEmptyFilters(getURLParameters()))
      }
    }
    if (stateProps.flow !== 'cin') {
      dispatchProps.runSearch(params, isBlock)
    } else {
      dispatchProps.runSearch(params)
    }
  }
})

const searchCheck = (obj, searchOptions) => {
  if (searchOptions && searchOptions.enableUrl) {
    let search = getURLParameters().search
    for (var param in search) {
      if (search.hasOwnProperty(param) && search[param] !== '' && search[param]) {
        obj[param] = search[param]
      }
    }
  }
  return obj
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FindPage)
